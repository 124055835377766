import React from "react";
import {
  Box,
  Text,
  Grid,
  Button,
  Flex,
  useColorModeValue,


} from "@chakra-ui/react";
import MetricBar from "./MetricBar.js";
import RatingButtonWithModal from "./RatingButtonWithModal.js";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
export default function CourseStats({
  ratingCount,
  id: courseID,
  averageCriteriaRating,
  loggedIn,
  setHasRated,
  handleSignIn,
  fetchComments,
  fetchCourseData,
  emailVerified,
  updateUser,
  setUpdateUser,
  isLargerThan768,
}) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Box
      flexGrow={1}
      flexShrink={0}
      mb={6}
      shadow="lg"
      borderRadius="lg"
      position="relative"
      bg={useColorModeValue("main.elementBgLight", "main.elementBgDark")}
    >
      {/* <Text fontSize="2xl" fontWeight="bold" ml={2}>
        {t("course_stats")}
      </Text> */}
      {ratingCount === 0 && (
        <Box
          bg={useColorModeValue(
            "rgba(255, 255, 255, 0.95)",
            "rgba(45, 55, 72, 0.97)"
          )}
          p={4}
          borderRadius="lg"
          textAlign="center"
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          zIndex={1}
        >
          <Text fontSize="lg" fontWeight="bold">
            {t("seems_quiet_here")}
          </Text>
          {loggedIn ? (
            emailVerified ? (
              <>
                <Text fontSize="lg" fontWeight="bold">
                  {t("be_first_to_rate")}
                </Text>
                <RatingButtonWithModal
                  courseID={courseID}
                  setHasRated={setHasRated}
                  fetchComments={fetchComments}
                  fetchCourseData={fetchCourseData}
                  updateUser={updateUser}
                  setUpdateUser={setUpdateUser}
                />
              </>
            ) : (
              <Text fontSize="lg" fontWeight="bold">
                {t("verify_email_to_rate")}
              </Text>
            )
          ) : (
            <>
              <Text fontSize="lg" fontWeight="bold">
                {t("signup_first_to_rate")}
              </Text>{" "}
              <Flex justifyContent="center" mt={5}>
                <Button
                  borderColor="main.primary"
                  variant="outline"
                  mr={2}
                  color="main.primary"
                  onClick={handleSignIn}
                >
                  {t("sign_in")}
                </Button>
                <Button
                  bg="main.primary"
                  variant="solid"
                  _hover={{ bg: "main.secondary" }}
                  color="white"
                  onClick={() => {
                    navigate(`/sign-up`);
                  }}
                >
                  {t("sign_up")}
                </Button>
              </Flex>{" "}
            </>
          )}
        </Box>
      )}
      <Grid templateColumns="repeat(2, 1fr)" gap={10} ml={2} mr={2}>
        <Box>

          <Text fontSize="xl" fontWeight="bold" align={"left"} mb={1}>
            {t("lecture")}
          </Text>
          <MetricBar
            name={"attendance"}
            value={
              ratingCount === 0 ? 0 : averageCriteriaRating?.attendance || 0
            }
            lowerScale={t("not_necessary")}
            upperScale={t("necessary")}
            isLargerThan768={isLargerThan768}
          />
          <MetricBar
            name={"previous_knowledge"}
            value={
              ratingCount === 0
                ? null
                : averageCriteriaRating?.previousKnowledge || 0
            }
            lowerScale={t("not_necessary")}
            upperScale={t("necessary")}
            isLargerThan768={isLargerThan768}
          />
          <MetricBar
            name={"content"}
            value={ratingCount === 0 ? 0 : averageCriteriaRating?.content || 0}
            lowerScale={t("boring")}
            upperScale={t("interesting")}
            isLargerThan768={isLargerThan768}
          />
        </Box>

        <Box>
          <Text fontSize="xl" fontWeight="bold" align={"right"} mb={1}>
            {t("exam")}
          </Text>

          <MetricBar
            name={"effort_per_credit"}
            value={
              ratingCount === 0
                ? 0
                : averageCriteriaRating?.effortPerCredit || 0
            }
            lowerScale={t("effort_greater_credit")}
            upperScale={t("effort_less_credit")}
            isLargerThan768={isLargerThan768}
          />
          <MetricBar
            name={"reproduction_vs_transfer"}
            value={
              ratingCount === 0
                ? 0
                : averageCriteriaRating?.reproductionVsTransfer || 0
            }
            lowerScale={t("reproduction")}
            upperScale={t("transfer")}
            isLargerThan768={isLargerThan768}
          />
          <MetricBar
            name={"math_intensity"}
            value={
              ratingCount === 0 ? 0 : averageCriteriaRating?.mathIntensity || 0
            }
            lowerScale={t("not_intense")}
            upperScale={t("intense")}
            isLargerThan768={isLargerThan768}
          />
        </Box>
      </Grid>
    </Box>
  );
}
