
import React from 'react';
import { HStack, Text, useMediaQuery } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { nameTranslation } from '../components/utils/Utils';
import { Box, Badge, useColorModeValue } from '@chakra-ui/react';


const CustomContent = ({ content }) => {
    return (
        < Text fontSize="md" >
            {content}
        </Text >)
}

const ReportFeedback = ({ content, parameters }) => {
    return (
        <Box>
            <Text fontSize="md">
                {content}
            </Text>
            <Box
                bg={useColorModeValue("main.elementBgLight", "main.elementBgDark")}
                m={1}
                borderRadius={"md"}
                shadow={"md"}
                p={2}
            >
                <Text fontSize="sm">
                    {parameters[0]}
                </Text>
            </Box>
        </Box>
    )
}


const EmbedCourse = ({ content, parameters }) => {
    const { t, i18n } = useTranslation();
    const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
    return (
        <Box >
            <Text fontSize="md">
                {content}
            </Text>
            <Box
                bg={useColorModeValue("gray.100", "#1A202C")}
                m={1}
                borderRadius={"md"}
                shadow={"md"}
                p={2}
                border={"1px solid"}
            >
                <HStack  >
                    <Badge colorScheme="green" borderRadius="full">
                        {parameters[0]}
                    </Badge>
                    <Text
                        maxW={isLargerThan768 ? "20vw" : "90vw"}
                        fontSize="sm"
                        fontWeight="bold"
                        style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                        }}

                    >
                        {nameTranslation(parameters[1], i18n.language)}
                    </Text>
                </HStack>
            </Box>
        </Box>

    )
}
const NotificationContent = ({
    type, content, parameters
}) => {
    switch (type) {
        case "NEW_RATING":
        case "NEW_ANSWER":
        case "NEW_QUESTION":
            return <EmbedCourse content={content} parameters={parameters} />;
        case "REPORT_FEEDBACK":
            return <ReportFeedback content={content} parameters={parameters} />;
        case "ANNOUNCEMENT":
            return <CustomContent content={content} />;
        case "OTHER":
            return <CustomContent content={content} />;
        default:
            return <CustomContent content={content} />;
    }


}
export default NotificationContent;



