import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  LinkBox,
  Badge,
  Code,
  Flex,
  Text,
  HStack,
  Icon,
  useMediaQuery,
  useColorModeValue,
  IconButton,
  Stack,
  Spacer,
} from "@chakra-ui/react";
import { FaUser, FaUniversity } from "react-icons/fa";
import { IoHeartSharp } from "react-icons/io5";
import { AiOutlineStar, AiFillStar } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { nameTranslation } from "./utils/Utils";
export const SearchResultCard = ({ course, border, remove_fav }) => {
  const navigate = useNavigate();
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const handleCardClick = () => {
    navigate(`/course/${course._id}`);
  };

  const rating = course.averageRating;
  const numOfRatings = course.ratingCount;
  const { t, i18n } = useTranslation();
  return (
    <LinkBox
      as="div"
      p={3}
      borderWidth={border ? 2 : 1}
      mb={2}
      bg={useColorModeValue("main.elementBgLight", "main.elementBgDark")}
      color={course.takable ? null : "gray.500"}
      borderRadius="md"
      shadow={border ? null : "base"}
      border={border ? null : "none"}
      cursor="pointer"
      transition="background-color 0.3s"
      _hover={!remove_fav ? {
        backgroundColor: course.takable ? useColorModeValue("main.secondary", "#648faa") : "gray.100",
      } : null}
      onClick={handleCardClick}
      width="100%"
    >
      <Flex justify="space-between" alignItems="flex-start">
        <Box width="100%">
          {isLargerThan768 ? (
            <HStack spacing={1} mb={2} width="100%">

              <Text
                fontSize="sm"
                fontWeight="bold"
                isTruncated
                mr={2}
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
                maxW={"50vw"}
                // w={isLargerThan768 ? "70%" : "60%"}
                title={nameTranslation(course.name, i18n.language)}
              >
                {nameTranslation(course.name, i18n.language)}
              </Text>
              <Code colorScheme="blue" m={"0"}>
                {course.courseCode}
              </Code>
              {remove_fav && <IconButton
                aria-label="Fav Course"
                icon={
                  <IoHeartSharp style={{ fontSize: '20px' }} color="main.primary" />
                }
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  remove_fav(course._id);
                }}
                color="main.primary"
                variant="ghost"

              />}
              <Spacer />
              {numOfRatings ? (
                <>
                  {Array.from({ length: 5 }).map((_, index) =>
                    index < rating ? (
                      <Icon
                        as={AiFillStar}
                        boxSize={4}
                        key={index}
                        color="yellow.400"
                      />
                    ) : (
                      <Icon
                        as={AiOutlineStar}
                        boxSize={4}
                        key={index}
                        color="gray.300"
                      />
                    )
                  )}
                  <Text fontSize="sm">({numOfRatings})</Text>
                </>
              ) : (
                <Text fontSize="sm" color="gray.500" width={"100%"}>
                  {t("courseCard.no_ratings_yet")}
                </Text>
              )}
            </HStack>
          ) : (<Box spacing={1} mb={2}>
            <Text
              fontSize="sm"
              fontWeight="bold"
              // isTruncated
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
              maxW={"85vw"}
              // w={isLargerThan768 ? "70%" : "60%"}
              title={nameTranslation(course.name, i18n.language)}
            >
              {nameTranslation(course.name, i18n.language)}
            </Text>
            <HStack>
              <Code colorScheme="blue" m={"0"}>
                {course.courseCode}
              </Code>
              {remove_fav && <IconButton
                aria-label="Fav Course"
                icon={
                  <IoHeartSharp style={{ fontSize: '20px' }} color="main.primary" />
                }
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  remove_fav(course._id);
                }}
                color="main.primary"
                variant="ghost"

              />}
              <Spacer />
              <HStack spacing={1}>
                {numOfRatings ? (
                  <>
                    {Array.from({ length: 5 }).map((_, index) =>
                      index < rating ? (
                        <Icon
                          as={AiFillStar}
                          boxSize={4}
                          key={index}
                          color="yellow.400"
                        />
                      ) : (
                        <Icon
                          as={AiOutlineStar}
                          boxSize={4}
                          key={index}
                          color="gray.300"
                        />
                      )
                    )}
                    <Text fontSize="sm">({numOfRatings})</Text>
                  </>
                ) : (
                  <Text fontSize="sm" color="gray.500" width={"100%"}>
                    {t("courseCard.no_ratings_yet")}
                  </Text>
                )}
              </HStack>
            </HStack>
          </Box>)}
          <HStack spacing={2} mb={2} maxW={"85vw"}>
            <Icon as={FaUniversity} />
            <Text fontSize="sm" whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis">
              {nameTranslation(course.department.name, i18n.language)}
            </Text>
          </HStack>
          <Box mb={1}>
            {course?.responsibles && course.responsibles.length > 0 && (
              <HStack spacing={2} mb={2}>
                <Icon as={FaUser} />
                <Text fontSize="sm">{course.responsibles[0].displayName}</Text>
              </HStack>
            )}
          </Box>
          <HStack spacing={2} mb={2}>
            {course?.area?.name?.en && <Badge>{course.area.name.en}</Badge>}
            {course?.languages?.map((language, index) => (
              <Badge key={index}>
                {language === "ENGLISH"
                  ? "\u0045\u004E"
                  : language === "GERMAN"
                    ? "\u0044\u0045"
                    : null}
              </Badge>
            ))}
            {course?.moduleLevel?.map((moduleLevel, index) => (
              <Badge key={index}>{moduleLevel}</Badge>
            ))}
            {course?.offeredInSemesters?.map((offeredInSemester, index) => (
              <Badge key={index}>
                {offeredInSemester === "SS"
                  ? t("summer")
                  : offeredInSemester === "WS"
                    ? t("winter")
                    : offeredInSemester === "IRREGULARLY"
                      ? t("irregular")
                      : null}
              </Badge>
            ))}
          </HStack>
          <HStack spacing={6}>
            <HStack spacing={1}>
              <Text fontSize="sm">{isLargerThan768 ? t("courseCard.average_grade") : "🎓"}</Text>
              <Text fontSize="sm" fontWeight={"bold"}>
                {course.averageGrade ? course.averageGrade.toFixed(1) : "-"}
              </Text>
            </HStack>

            <HStack spacing={1}>
              <Text fontSize="sm">{isLargerThan768 ? t("courseCard.credits") : "✨"}</Text>
              <Text fontSize="sm" fontWeight={"bold"}>
                {course.credits ? course.credits : "-"} {!isLargerThan768 && " ECTS"}
              </Text>
            </HStack>

            <HStack spacing={1}>
              <Text fontSize="sm">{isLargerThan768 ? t("courseCard.recommendation_rate") : "👍"}</Text>
              <Text fontSize="sm" fontWeight={"bold"}>
                {course.wouldRecommend !== null
                  ? `${course.wouldRecommend.toFixed(2) * 100}%`
                  : "-"}
              </Text>
            </HStack>


          </HStack>
        </Box>

      </Flex>
    </LinkBox>
  );
};

// TODO: MEtriken unten anpassen für mobil, Testen für full screen, auch suche testen
