import React from "react";
import { InfoIcon } from '@chakra-ui/icons'
import {
  Box, Flex, Text, Progress, Tooltip, useColorModeValue, useMediaQuery,
  Popover, PopoverTrigger, PopoverContent, PopoverArrow, PopoverCloseButton, PopoverHeader, PopoverBody
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export default function MetricBar({ name, value, upperScale, lowerScale, isLargerThan768 }) {
  const { t } = useTranslation(); 
  return (
    <Box mb={2} bg={useColorModeValue("main.elementBgLight", "main.elementBgDark")} >
      <Flex alignItems="center" mb={2}>
        <Text fontWeight="bold" mb={isLargerThan768 ? 0.5 : 0} fontSize={isLargerThan768 ? null : "sm"}>
          {t(name)}
        </Text>
        <Popover>
          <PopoverTrigger>
            <InfoIcon color="gray.500" ml={3} />
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverHeader>{t(name)}</PopoverHeader>
            <PopoverBody>

              {t("metrics_explanation." + name).split('\n').map((line, index) => (
                <Text fontWeight={"light"} mb={2}>
                  {line}
                </Text>
              ))}
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Flex>
      <Tooltip label={value + "%"} openDelay={500}>
        {/* TODO: Progress bar color theme */}
        <Progress
          value={value}
          // colorScheme="main"
          mb={2}
          borderRadius="md"
          zIndex={0}
        />
      </Tooltip>
      <Box display="flex" justifyContent="space-between">
        <Text fontSize={isLargerThan768 ? null : "xs"}>
          {lowerScale}</Text>
        <Text fontSize={isLargerThan768 ? null : "xs"}>{upperScale}</Text>
      </Box>
    </Box>
  );
}
