import { Box, Button, Flex, HStack, Spacer, Tag, Text, Tooltip, useColorModeValue, Popover, PopoverTrigger, PopoverContent, PopoverArrow, PopoverCloseButton, PopoverHeader, PopoverBody } from "@chakra-ui/react";

import { InfoIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";

import { useMediaQuery } from "@chakra-ui/react";
import { InfoOutlineIcon } from "@chakra-ui/icons";

export default function CommentQuestionHider({ isSignedIn, emailVerified, ratedInLast6Months, handleSignIn }) {
    const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
    const { t } = useTranslation();
    const notEnoughRatings = ratedInLast6Months < 2;
    return (

        <Box
            position="absolute"
            top="0"
            left="0"
            right="0"
            bottom="0"
            bg={useColorModeValue("rgba(255, 255, 255, 0.7)", "rgba(45, 55, 72, 0.8)")}
            backdropFilter="blur(5px)"
            zIndex="10"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            borderRadius="lg"
            px={1}


        >
            <Text fontSize={isLargerThan768 ? "2xl" : "lg"} fontWeight="bold" textAlign="center">
                {notEnoughRatings & isSignedIn & emailVerified? t("not_enough_ratings_title") : t("want_to_see_comments_and_ask_questions")}
            </Text>
            {!isSignedIn ? <Flex justifyContent="center" mt={4}>
                <Box alignItems="center" textAlign="center">
                    <Text fontSize={isLargerThan768 ? "xl" : "md"} fontWeight="bold">
                        {t("sign_in_or_create_account")}
                    </Text>
                    <HStack mt={4} alignItems="center" textAlign="center">
                        <Spacer />
                        <Button
                            borderColor="main.primary"
                            variant="outline"
                            mr={2}
                            color="main.primary"
                            onClick={handleSignIn}
                        >
                            {t("sign_in")}
                        </Button>
                        <Button
                            bg="main.primary"
                            variant="solid"
                            _hover={{ bg: "main.secondary" }}
                            color="white"
                            onClick={() => {
                                navigate(`/sign-up`);
                            }}
                        >
                            {t("sign_up")}
                        </Button>
                        <Spacer />
                    </HStack>
                </Box>
            </Flex> : (
                <>
                    {t(notEnoughRatings ? "not_enough_ratings_description" : "verify_email").split('\n').map((line, i) => (
                        <Text key={i} fontSize={isLargerThan768 ? "xl" : "md"} fontWeight={"light"}>
                            {line}
                        </Text>
                    ))}
                    {notEnoughRatings && (
                        <HStack><Tag my={4} colorScheme="green" variant={"outline"} fontSize={isLargerThan768 ? "xl" : "md"} size={isLargerThan768 ? "lg" : "md"}>
                            {t(ratedInLast6Months == 1 ? "not_enough_ratings_status_one" : "not_enough_ratings_status_zero")}
                        </Tag>
                            
                            <Popover>
                                <PopoverTrigger>
                                    <InfoIcon color="gray.500"  />
                                </PopoverTrigger>
                                <PopoverContent>
                                    <PopoverArrow />
                                    <PopoverCloseButton />
                                    <PopoverHeader>{t("how_does_it_work")}</PopoverHeader>
                                    <PopoverBody>

                                    {t("not_enough_ratings_disclaimer").split('\n').map((line, i) => (
                                <Text key={i} fontSize={isLargerThan768 ? "s" : "xs"} fontWeight={"light"} >
                                    {line}
                                </Text>
                            ))}
                                    </PopoverBody>
                                </PopoverContent>
                            </Popover>

                        </HStack>
                    )}
                </>
            )}
        </Box>
    );
}