import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  HStack,
  Stack,
  Flex,
  Button,
  Divider,
  Badge,
  IconButton,
  Select,
  Textarea,
  useToast,
  useColorModeValue,
  Tooltip,
  Spacer,
} from "@chakra-ui/react";

import { CheckIcon, EditIcon } from "@chakra-ui/icons";
import { IoHeartSharp, IoHeartOutline } from "react-icons/io5";
import { useMediaQuery } from "@chakra-ui/react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import RatingButtonWithModal from "./RatingButtonWithModal";
import VerifyEMailComponent from "./VerifyEMailComponent";
import CourseDetails from "./CourseDetails";
import CourseStats from "./CourseStats";
import EditCourseDetails from "./EditCourseDetails";
import Comment from "./Comment";
import AskQuestionTemplate from "./AskQuestionTemplate";
import CommentQuestionHider from "./CommentQuestionHider";
import Question from "./Question";
import { useTranslation } from "react-i18next";
import { nameTranslation } from "./utils/Utils";

export default function CourseDesktop({
  isSignedIn,
  allowedRoles,
  role,
  isEditingCourseName,
  setIsEditingCourseName,
  editedCourseName,
  setEditedCourseName,
  handleCourseNameChange,
  emailVerified,
  hasRated,
  hasFaved,
  courseData,
  courseCode,
  fetchQuestionsAndComments,
  updateUser,
  setUpdateUser,
  isEditingDetails,
  setIsEditingDetails,
  handleSignIn,
  commentData,
  questionData,
  handleCommentSelectChange,
  fetchCourseData,
  setHasRated,
  navigate,
  favCourse,
  ratedInLast6Months

}) {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Box
        alignItems="center"
        mb={4}
        position="sticky"
        top="79"
        zIndex={12}
        bgGradient={useColorModeValue(
          "linear( rgba(237, 242, 247,1) , rgba(237, 242, 247,0.1))",
          "linear( rgba(45, 55, 72,1) , rgba(45, 55, 72, 0.1))"
        )}
      >
        {isSignedIn && allowedRoles.includes(role) && <VerifyEMailComponent />}
        <HStack>
          <Box flex="1" width="100%" mt={2} ml={4}>
            {isEditingCourseName ? (
              <HStack>
                <Textarea
                  value={editedCourseName}
                  onChange={(e) => setEditedCourseName(e.target.value)}
                  size="lg"
                />
                <IconButton
                  aria-label="Save course name"
                  icon={<CheckIcon />}
                  size="sm"
                  color="main.primary"
                  variant={"ghost"}
                  onClick={handleCourseNameChange}
                />
              </HStack>
            ) : (
              <HStack>
                <Text
                  mb={3}
                  fontSize={
                    nameTranslation(courseData?.name, i18n.language).length > 80
                      ? "3xl"
                      : "4xl"
                  }
                  fontWeight="bold"
                  style={{ lineHeight: "1" }}
                >
                  {nameTranslation(courseData?.name, i18n.language)}
                </Text>
                {isSignedIn && <Tooltip label={hasFaved ? t("unfav_course") : t("fav_course")}>
                  <IconButton
                    aria-label="Fav Course"
                    icon={
                      hasFaved ? (
                        <IoHeartSharp style={{ fontSize: '24px' }} color="main.primary" />
                      ) : (
                        <IoHeartOutline style={{ fontSize: '24px' }} color="main.elementBgLight" />
                      )
                    }
                    size="lg"
                    color="main.primary"
                    variant="ghost"
                    onClick={() => {
                      favCourse(courseData?.id);
                    }}
                  />
                </Tooltip>}

                {courseData?.isAdmin && (
                  <IconButton
                    aria-label="Edit course details"
                    icon={<EditIcon />}
                    size="sm"
                    color="main.primary"
                    variant={"ghost"}
                    onClick={() => setIsEditingCourseName(true)}
                  />
                )}
              </HStack>
            )}

            {!emailVerified ||
              hasRated ||
              courseData?.ratingCount == 0 ||
              !(isSignedIn && allowedRoles.includes(role)) ? null : (
              <RatingButtonWithModal
                courseID={courseData?.id}
                setHasRated={setHasRated}
                fetchComments={fetchQuestionsAndComments}
                fetchCourseData={fetchCourseData}
                updateUser={updateUser}
                setUpdateUser={setUpdateUser}
              />
            )}
          </Box>

          <Stack direction="row" spacing={4} mr={6} cursor={"default"} >
            <Tooltip label={t("stars")}>

              <Box align="center" mr={1}>
                <Text fontSize="2xl" fontWeight="bold">
                  ⭐️ {courseData?.averageRating ? courseData?.averageRating.toFixed(1) : "-"}
                </Text>
              </Box>
            </Tooltip>
            <Tooltip label={t("would_recommend")}>
              <Box align="center">
                <Text fontSize="2xl" fontWeight="bold">
                  👍{" "}
                  {courseData?.ratingCount > 0
                    ? (courseData?.wouldRecommend * 100).toFixed() + " %"
                    : "-"}
                </Text>

              </Box>
            </Tooltip>

            <Tooltip label={t("average_grade")}>
              <Box align="center">
                <Text fontSize="2xl" fontWeight="bold">
                  🎓{" "}
                  {courseData?.ratingCount > 1
                    ? courseData?.averageGrade.toFixed(1)
                    : "-" || "-"}
                </Text>

              </Box>
            </Tooltip>
          </Stack>
        </HStack>
      </Box >
      <Box mx={4} pos="relative" top="75">
        <Box my={4} mt="0rem">
          <Stack direction="row" justify="space-between" width="full">
            {isEditingDetails ? (
              <EditCourseDetails
                courseData={courseData}
                courseID={courseData?.id}
                setIsEditing={setIsEditingDetails}
                fetchCourseData={fetchCourseData}
              />
            ) : (
              <Flex alignItems={"stretch"} mt={1} spacing={2} width="full">
                <Box
                  mb={6}
                  flex={1}
                  flexGrow={1}
                  flexShrink={0}
                  shadow="lg"
                  borderRadius="lg"
                  bg={useColorModeValue(
                    "main.elementBgLight",
                    "main.elementBgDark"
                  )}
                  mr={2}
                >
                  <HStack spacing={4} px={4} py={2} justify="space-between">
                    <Text fontSize="2xl" fontWeight="bold" ml={2}>
                      {t("details")}
                    </Text>
                    <Box flex={1} />
                    {courseData?.isAdmin ? (
                      <Button
                        onClick={() => setIsEditingDetails(true)}
                        variant="outline"
                        color="main.primary"
                        borderColor="main.primary"
                        size="sm"
                        rightIcon={<EditIcon />}
                      >
                        {t("edit")}
                      </Button>
                    ) : (
                      isSignedIn &&
                      allowedRoles.includes(role) &&
                      courseData &&
                      (courseData?.partOfTheDegreeProgram ? (
                        <Badge colorScheme="green" variant="outline">
                          {t("part_of_program")}
                        </Badge>
                      ) : (
                        <Badge colorScheme="red" variant="outline">
                          {t("not_part_of_program")}
                        </Badge>
                      ))
                    )}
                  </HStack>

                  <CourseDetails
                    courseData={courseData}
                    courseCode={courseCode}
                  />
                </Box>

                <CourseStats
                  {...courseData}
                  loggedIn={isSignedIn && allowedRoles.includes(role)}
                  setHasRated={setHasRated}
                  handleSignIn={handleSignIn}
                  fetchComments={fetchQuestionsAndComments}
                  fetchCourseData={fetchCourseData}
                  emailVerified={emailVerified}
                  updateUser={updateUser}
                  setUpdateUser={setUpdateUser}
                  isLargerThan768={true}
                />
              </Flex>
            )}
          </Stack>
          <Flex mb={6} position="relative" minHeight="30vh">
            {(!isSignedIn || !emailVerified || ratedInLast6Months < 2) && (
              <CommentQuestionHider
                isSignedIn={isSignedIn}
                emailVerified={emailVerified}
                ratedInLast6Months={ratedInLast6Months}
                handleSignIn={handleSignIn}
              />

            )}
            <Box flex="1" mr={4}>
              <HStack justifyContent="space-between">
                <Text fontSize="2xl" fontWeight="bold">
                  {t("ratings") + " (" + (courseData?.ratingCount || "0") + ")"}
                </Text>
                <Select
                  width="30%"
                  height="1.9rem"
                  bg={useColorModeValue("main.elementBgLight", "main.elementBgDark")}
                  onChange={handleCommentSelectChange}
                  isDisabled={courseData?.ratingCount == 0}
                >
                  <option value="top">{t("top")}</option>
                  <option value="stars">{t("stars")}</option>
                  <option value="newest">{t("newest")}</option>
                  <option value="oldest">{t("oldest")}</option>
                </Select>
              </HStack>
              <Divider my={2} />
              {commentData && (
                <TransitionGroup>
                  {commentData.map((comment, index) => (
                    (comment.text || comment.isAuthor || courseData?.isAdmin) && <CSSTransition key={comment.id} classNames="fade" timeout={500}>
                      <Comment
                        key={index}
                        {...comment}
                        courseCode={courseCode}
                        fetchComments={fetchQuestionsAndComments}
                        fetchCourseData={fetchCourseData}
                        isAdmin={courseData?.isAdmin}
                        updateUser={updateUser}
                        setUpdateUser={setUpdateUser}
                      />
                    </CSSTransition>
                  ))}
                </TransitionGroup>
              )}
            </Box>
            <Box flex="1">
              <Box display="flex" justifyContent="space-between">
                <Text fontSize="2xl" fontWeight="bold">
                  {t("q_and_a")}
                </Text>
              </Box>
              <Divider my={2} />
              <AskQuestionTemplate
                {...courseData}
                fetchQuestions={fetchQuestionsAndComments}
                fetchCourseData={fetchCourseData}
                updateUser={updateUser}
                setUpdateUser={setUpdateUser}
              />
              {questionData && (
                <TransitionGroup>
                  {questionData.map((questionData, index) => (
                    <CSSTransition key={questionData.id} classNames="fade" timeout={300}>
                      <Question
                        key={index}
                        {...questionData}
                        courseCode={courseCode}
                        courseID={courseData?.id}
                        fetchQuestions={fetchQuestionsAndComments}
                        isAdmin={courseData?.isAdmin}
                        updateUser={updateUser}
                        setUpdateUser={setUpdateUser}
                      />
                    </CSSTransition>
                  ))}
                </TransitionGroup>
              )}
            </Box>
          </Flex>

        </Box>

      </Box>

    </>
  );
}
