import React from "react";
import { InfoIcon } from '@chakra-ui/icons'
import { useTranslation } from "react-i18next";
import {
  Box,
  Flex,
  Text,
  Slider,
  SliderFilledTrack,
  SliderTrack,
  SliderThumb,
  useMediaQuery,
  HStack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Spacer,

} from "@chakra-ui/react";

export default function RateDetailSlider({
  title,
  lowerScale,
  upperScale,
  value,
  setValue,
}) {
  const handleSliderChange = (newValue) => {
    setValue(newValue);
  };
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const { t } = useTranslation();
  return (
    <Flex my={3}>
      <Box flex={1}>
        <HStack>
          <Text fontSize={"md"}>{t(title)}</Text>
          <Spacer />
          <Popover>
            <PopoverTrigger>
              <InfoIcon color="gray.500" ml={1} />
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverHeader>{t(title)}</PopoverHeader>
              <PopoverBody>

                {t("metrics_explanation." + title).split('\n').map((line, index) => (
                  <Text fontWeight={"light"} mb={2}>
                    {line }
                  </Text>
                ))}
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </HStack>
      <Slider
        size={"lg"}
        value={value}
        onChange={(newValue) => handleSliderChange(newValue)}
      >
        <SliderTrack>
          <SliderFilledTrack />
        </SliderTrack>
        <SliderThumb />
      </Slider>

      <Box display="flex" justifyContent="space-between">
        <Text fontSize={isLargerThan768 ? "sm" : "xs"} >{lowerScale}</Text>
        <Box flex="1" />
        <Text fontSize={isLargerThan768 ? "sm" : "xs"} textAlign={"right"}>
          {upperScale}
        </Text>
      </Box>
    </Box>
    </Flex >
  );
}
