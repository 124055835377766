import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormLabel,
    HStack,
    Heading,
    Input,
    InputGroup,
    InputRightElement,
    Stack,
    Text,
    Icon,
    Tooltip,
    Spacer,
} from "@chakra-ui/react";

import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import request from "../api";
import { FaUserFriends } from "react-icons/fa";

import { MdContentCopy } from "react-icons/md";



export default function CreateInviteCode({ user }) {
    const [inviteCode, setInviteCode] = useState("");
    const [inviteLink, setInviteLink] = useState("");
    // const [hasValidCode, setHasValidCode] = useState(false);
    const [valid_for_days, setValidForDays] = useState(30);
    const [valid_for_users, setValidForUsers] = useState(5);
    const [get_karma, setGetKarma] = useState(true);

    const { t } = useTranslation();
    useEffect(() => {
        hasValidInviteCode();
    }, []);
    const hasValidInviteCode = async () => {
        try {
            const response = await request(
                `${process.env.REACT_APP_BACKEND_URL}/api/v1/users/has-valid-invite-code`,
                "GET",
            );
            if (response.inviteCode) {
                // setHasValidCode(true);
                setInviteCode(response.inviteCode);
                setInviteLink(`${process.env.REACT_APP_FRONTEND_URL}/sign-up?inviteCode=${response.inviteCode}`);
            }
        } catch (error) {
            console.log(error);
        }
    }
    const createInviteCode = async (password) => {
        try {
            const response = await request(
                `${process.env.REACT_APP_BACKEND_URL}/api/v1/users/invite-code`,
                "PUT",
                {
                    validForDays: valid_for_days,
                    validForUsers: valid_for_users,
                    getKarma: get_karma,
                },
            );
            setInviteCode(response.inviteCode);
            setInviteLink(`${process.env.REACT_APP_FRONTEND_URL}/sign-up?inviteCode=${response.inviteCode}`);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Box display="flex" alignItems="center">
            <Box flex="1" ml="2">
                <Heading fontSize="xxl" mb="4">
                    <Box as="span" display="flex" alignItems="center">
                        <FaUserFriends size={24} style={{ marginRight: "0.5rem" }} />
                        {t("invite_your_friends")}
                    </Box>
                </Heading>
                <Text fontSize="sm" mb="2" maxW="500px">
                    {t("you_can_invite_your_friends")}
                </Text>

                {user.role === "ADMIN" && <HStack my={5} >
                    <Text fontWeight={"bold"} >{t("valid_for")}</Text>

                    <Input
                        type="number"
                        maxW={"50px"}
                        size={"sm"}
                        value={valid_for_users}
                        onChange={(e) => {
                            setValidForUsers(e.target.value);
                        }}
                        textAlign="right"
                    />
                    <Text >{t("users") + " " + t("and")}</Text>

                    <Input
                        type="number"
                        value={valid_for_days}
                        onChange={(e) => {
                            setValidForDays(e.target.value);
                        }}
                        size={"sm"}
                        maxW={"50px"}
                        textAlign="right"

                    />
                    <Text >{t("days")}</Text>

                    <Spacer />
                    <Checkbox isChecked={get_karma}
                        onChange={(e) => {
                            setGetKarma(e.target.checked);
                        }
                        }>
                        {t("get_karma")}
                    </Checkbox>
                </HStack>}

                <HStack spacing="2">
                    <Tooltip label={inviteLink ? t("has_valid_invite_code") : null} >
                        <Button
                            colorScheme="blue"
                            size="sm"
                            onClick={() => {
                                createInviteCode();
                            }}
                            borderRadius="md"
                            px={8}
                            isDisabled={inviteCode !== "" && user.role !== "ADMIN"}
                        >
                            {t("create_invite_code")}
                        </Button>
                    </Tooltip>
                    <Input
                        onChange={(e) => {
                        }}

                        value={inviteLink}
                        readOnly={true}
                        borderColor="gray.800"
                        _placeholder={t("your_invite_link")}
                        place
                        id="invite_link"
                        color="gray.800"
                        size="sm"
                    />
                    <Icon
                        as={MdContentCopy}
                        color="gray.600"
                        w={6}
                        h={6}
                        cursor={"pointer"}
                        position={"relative"}
                        onClick={() => {
                            navigator.clipboard.writeText(inviteLink);
                        }
                        } />

                </HStack>
            </Box>
        </Box>
    );
}
