import React, { useEffect, useState, useContext, useRef, } from "react";
import AuthContext from "../AuthContext.js";
import { TriangleUpIcon, TriangleDownIcon } from "@chakra-ui/icons";
import Navbar from "../components/Navbar";
import {
  Avatar,
  Badge,
  Card,
  CardBody,
  Box,
  Button,
  Center,
  Checkbox,
  Collapse,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Progress,
  Show,
  SimpleGrid,
  Spacer,
  Stack,
  Switch,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  useToast,
  VStack,
  useMediaQuery,
  useColorModeValue,
  List,
} from "@chakra-ui/react";
import { Form, useNavigate } from "react-router-dom";
import { EditIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";
import request from "../api";
import { Radar } from "react-chartjs-2";
import "chart.js/auto";
import UserAvatar from "../components/UserAvatar";
import DeleteAccount from "../components/DeleteAccount";
import VerifyEMailComponent from "../components/VerifyEMailComponent";
import BottomBar from "../components/BottomBar";
import { SearchResultCard } from "../components/SearchResultCard";
import CreateInviteCode from "../components/CreateInviteCode";

function Profile() {
  const { isSignedIn } = useContext(AuthContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [editActive, setEditActive] = useState(false);
  const [user, setUser] = useState("");
  const [username, setUsername] = useState("");
  const [oldUsername, setOldUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [workingStudent, setWorkingStudent] = useState(false);
  const [internship, setInternship] = useState(false);
  const [fulltime, setFulltime] = useState(false);
  const { isOpen, onToggle } = useDisclosure();
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [passwordCorrect, setPasswordCorrect] = useState(false);
  const [newPasswordError, setNewPasswordError] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [degreeCredits, setDegreeCredits] = useState(180);
  const [gpa, setGpa] = useState(0);
  const [favs, setFavs] = useState([]);
  const toast = useToast();
  const [updateUser, setUpdateUser] = useState(false);
  const [isPicturePopUpOpen, setIsPicturePopUpOpen] = useState(false);
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const fileInputRef = useRef(null);
  const handleFileUpload = () => {
    fileInputRef.current.click();
  };
  const handleAvatarClick = () => {
    setIsPicturePopUpOpen(true);
  };


  // const karmaDifference = user?.karmaRankings?.currentMonth - user?.karmaRankings?.lastMonth;
  // 0 if they ? do not exist

  let karmaDifference = 0;
  if (user?.karmaRankings?.currentMonth && user?.karmaRankings?.lastMonth) {
    karmaDifference = user?.karmaRankings?.currentMonth - user?.karmaRankings?.lastMonth;
  }




  const isKarmaUp = karmaDifference > 0;


  const handleCancel = () => {
    setIsPicturePopUpOpen(false);
  };

  function convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }

  useEffect(() => {
    fetchUserData();
    fetchProfile();
    fetchUserFavorites();
  }, []);

  useEffect(() => {
    setUsernameError("");
  }, [username]);

  useEffect(() => {
    if (newPassword.length > 0 && newPassword.length < 8) {
      setNewPasswordError(t("signup_error.invalid_password"));
    } else {
      setNewPasswordError("");
    }
  }, [newPassword]);
  const fetchUserData = async () => {
    try {
      const response = await request(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/users/profile`,
        "GET"
      );
      const data = await response;
      setUsername(data.username);
      setEmail(data.email);
      setGpa(data.gpa);
    } catch (error) {
      console.error("Failed to fetch user data:", error);
    }
  };

  const fetchUserFavorites = async () => {
    try {
      const response = await request(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/users/favorites`,
        "GET"
      );

      setFavs(response);
    } catch (error) {
      console.error("Failed to fetch user favorites:", error);
    }
  };

  const fetchProfile = async () => {
    try {
      const user = await request(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/auth/user-profile`,
        "GET"
      );
      setUser(user);
    } catch (error) {
      console.error("Failed to fetch user data:", error);
    }
  };

  const saveUserData = async () => {
    if (username.trim().toLowerCase() === oldUsername.trim().toLowerCase()) {
      setEditActive(false);
      return;
    }
    try {
      const body = {
        username: username,
        email: email,
      };

      const response = await request(`${process.env.REACT_APP_BACKEND_URL}/api/v1/users/username`, "PUT", body);
      if (response.error) {
        setUsernameError(t("signup_error." + response.error));
        return
      }
      fetchProfile();
      fetchUserData();
      setEditActive(false);
      setUpdateUser(!updateUser);
      toast({
        title: "Success",
        description: "Your settings were saved successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Failed to update user:", error);
      toast({
        title: "Could not save settings",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setEditActive(true);
    }
  };

  const handleVerifyPassword = async (password) => {
    try {
      const response = await request(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/auth/verify-password`,
        "POST",
        { password: password }
      );

      setPasswordCorrect(true);
      setPasswordError(false);
    } catch (error) {
      setPasswordCorrect(false);
      setPasswordError(true);
    }
  };
  const remove_fav = async (courseID) => {
    try {
      await request(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/courses/${courseID}/favorite`,
        'PUT'
      );
      const updatedFavs = favs.filter((fav) => fav._id !== courseID);
      setFavs(updatedFavs);
    } catch (error) {
      console.error('Error favoriting course:', error);
    }
  }

  const handleChangePassword = async () => {
    try {
      const response = await request(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/auth/change-password`,
        "POST",
        { oldPassword: password, newPassword: newPassword }
      );

      toast({
        title: "Password changed",
        description: "Your password was changed successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setPassword("");
      setNewPassword("");
      setRepeatPassword("");
      setPasswordCorrect(false);
      setPasswordError(false);
    } catch (error) {
      setPasswordCorrect(false);
      setPasswordError(true);
      console.error("Failed to verify password:", error);
    }
  };

  function createLineBreaks(labelName, maxCharactersInLine) {
    const words = labelName.split(" ");
    const result = [];
    let currentLine = "";

    for (let i = 0; i < words.length; i++) {
      const word = words[i];
      if (currentLine.length + word.length <= maxCharactersInLine) {
        currentLine += (currentLine.length > 0 ? " " : "") + word;
      } else {
        result.push(currentLine);
        currentLine = word;
      }
    }

    if (currentLine.length > 0) {
      result.push(currentLine);
    }

    return result;
  }



  return (
    <Flex direction="column" minH="100vh">
      {!isSignedIn ? navigate("/sign-in") : ""}
      <Navbar loggedIn={isSignedIn} updateUser={updateUser} />
      <Box mt={90}>{isSignedIn && <VerifyEMailComponent />}</Box>

      <Box spacing={10} my={5}>
        <Box
          shadow="lg"
          borderRadius="lg"
          mx={2}
          bg={useColorModeValue("main.elementBgLight", "main.elementBgDark")}
        >
          <Center>
            <HStack spacing={0} w={isLargerThan768 ? "60%" : "90%"}>
              <Box display="flex" alignItems="center" justifyContent="center">
                <Text
                  role="img"
                  aria-label="currentRank"
                  fontSize={isLargerThan768 ? "xxx-large" : "xx-large"}
                >
                  {user.rank?.currentRank.emoji}
                </Text>
              </Box>
              <VStack spacing={2} my={2} w={"full"} alignItems="center">
                <Text as="b" fontSize="3xl">
                  🔥 {user.karmaPoints}
                </Text>
                <VStack spacing={2}>
                  <Text fontSize="sm">
                    {t("compared_to_last_month")+": "}
                    {isKarmaUp ? (
                      <Text as="span" color="green.500">
                        ▲ {karmaDifference}
                      </Text>
                    ) : (
                      <Text as="span" color="red.500">
                        ▼ {Math.abs(karmaDifference)}
                      </Text>
                    )}
                  </Text>
                </VStack>
                <HStack spacing={2}>
                  <Text fontSize="xl">{t("rank")}</Text>
                  <Text fontSize="xl" fontWeight={"bold"}>
                    {user.rank?.currentRank.name}
                  </Text>
                </HStack>
                <Progress
                  value={(user.karmaPoints / user.rank?.nextRank.minKarma) * 100}
                  width="100%"
                  borderRadius="full"
                ></Progress>
                <Text fontSize="sm">
                  {t("next_rank_min_karma", {
                    minKarma: user.rank?.nextRank.minKarma - user.karmaPoints,
                  })}
                </Text>
                {/* nice button that forwards to /leaderboaard */}
                <Button
                  colorScheme="blue"
                  onClick={() => navigate("/leaderboard")}
                >
                  Leaderboard
                </Button>
              </VStack>
              <Box display="flex" alignItems="center" justifyContent="center">
                <Text
                  role="img"
                  aria-label="currentRank"
                  fontSize={isLargerThan768 ? "xxx-large" : "xx-large"}
                >
                  {user.rank?.nextRank.emoji}
                </Text>
              </Box>
            </HStack>
          </Center>
        </Box>
        <SimpleGrid templateColumns={isLargerThan768 ? "auto 49%" : null} templateRows={isLargerThan768 ? null : "auto auto"} maxW={"100%"}
          spacing={4} px={2}>
          <Box
            shadow="lg"
            borderRadius="lg"
            bg={useColorModeValue("main.elementBgLight", "main.elementBgDark")}
            mt={5}
            maxW={"100%"}
          >
            <Heading ml={5} mb={4} mt={4}>
              {t("your_profile")}
            </Heading>
            <Tabs variant="enclosed">
              <TabList>

                <Tab >{t("invite_friends")}</Tab>
                <Tab >{t("personal_details")}</Tab>
                <Tab >{t("change_password")}</Tab>
                <Tab >{t("delete_account.delete_button")}</Tab>

              </TabList>
              <TabPanels>
                <TabPanel>
                  <CreateInviteCode user={user} />
                </TabPanel>
                <TabPanel>
                  <HStack spacing={5} ml={5} mb={5}>
                    <UserAvatar
                      user={user}
                      size={"xl"}
                    // onClick={handleAvatarClick}
                    />

                    <Text as="b" fontSize="3xl">
                      {user.username}
                    </Text>
                    <IconButton
                      aria-label="Edit profile"
                      icon={<EditIcon />}
                      variant="none"
                      size="xs"
                      onClick={() => {
                        setEditActive(!editActive);
                        setOldUsername(username);
                        setUsernameError("");
                      }}
                    />
                    <Button
                      visibility={editActive ? "visible" : "hidden"}
                      isDisabled={
                        username.trim() === "" || username.trim().length < 3 || username.trim().length > 20
                      }
                      color="white"
                      bg="main.primary"
                      _hover={{ bg: "main.secondary" }}
                      onClick={saveUserData}
                      size="xs"
                    >
                      {t("save")}
                    </Button>
                  </HStack>
                  <FormControl isDisabled={!editActive} ml={5} maxWidth={300}>
                    <FormLabel>{t("username")}</FormLabel>
                    <Input
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                    {usernameError && (
                      <Text color="red.500" fontSize="sm">
                        {usernameError}
                      </Text>)}
                  </FormControl>

                  <FormControl isDisabled={true} ml={5} maxWidth={300}>
                    <FormLabel>{t("university_email")}</FormLabel>
                    <Input
                      value={email}
                    />
                  </FormControl>
                </TabPanel>
                <TabPanel>
                  <Box ml="5" rounded="md">
                    <Heading fontSize="xxl" mb="4">
                      {t("change_password")}
                    </Heading>
                    <FormControl maxWidth={300} isInvalid={passwordError}>
                      <FormLabel>{t("old_password")}</FormLabel>
                      <InputGroup>
                        <Input
                          type={showOldPassword ? "text" : "password"}
                          colorScheme="green"
                          value={password}
                          onChange={(e) => {
                            setPassword(e.target.value);
                            handleVerifyPassword(e.target.value);
                          }}
                        />
                        <InputRightElement width="4.5rem">
                          <Button
                            h="1.75rem"
                            size="sm"
                            onClick={() => setShowOldPassword(!showOldPassword)}
                          >
                            {showOldPassword ? t("hide") : t("show")}
                          </Button>
                        </InputRightElement>
                      </InputGroup>
                      {passwordCorrect && (
                        <FormHelperText color="green">
                          {t("old_password_correct")}
                        </FormHelperText>
                      )}
                      <FormErrorMessage>
                        {t("old_password_doesnt_match")}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      maxWidth={300}
                      isInvalid={newPassword !== "" && newPassword.length < 8}
                    >
                      <FormLabel>{t("new_password")}</FormLabel>
                      <InputGroup>
                        <Input
                          type={showNewPassword ? "text" : "password"}
                          value={newPassword}
                          onChange={(e) => setNewPassword(e.target.value)}
                        />
                        <InputRightElement width="4.5rem">
                          <Button
                            h="1.75rem"
                            size="sm"
                            onClick={() => setShowNewPassword(!showNewPassword)}
                          >
                            {showNewPassword ? t("hide") : t("show")}
                          </Button>
                        </InputRightElement>
                      </InputGroup>
                      {/* <FormErrorMessage>
                        {t("password_length_error")}
                      </FormErrorMessage> */}
                      {newPasswordError && (
                        <Text color="red.500" fontSize="sm">
                          {newPasswordError}
                        </Text>)}
                    </FormControl>
                    <FormControl
                      maxWidth={300}
                      isInvalid={newPassword !== repeatPassword}
                    >
                      <FormLabel>{t("repeat_new_password")}</FormLabel>
                      <InputGroup>
                        <Input
                          type={showRepeatPassword ? "text" : "password"}
                          value={repeatPassword}
                          onChange={(e) => setRepeatPassword(e.target.value)}
                        />
                        <InputRightElement width="4.5rem">
                          <Button
                            h="1.75rem"
                            size="sm"
                            onClick={() =>
                              setShowRepeatPassword(!showRepeatPassword)
                            }
                          >
                            {showRepeatPassword ? t("hide") : t("show")}
                          </Button>
                        </InputRightElement>
                      </InputGroup>
                      <FormErrorMessage>
                        {t("repeat_password_doesnt_match")}
                      </FormErrorMessage>
                    </FormControl>
                    <HStack mt="4">
                      <Button
                        mt="4"
                        isDisabled={
                          newPassword !== repeatPassword ||
                          !newPassword ||
                          !repeatPassword ||
                          !passwordCorrect || newPasswordError
                        }
                        onClick={() => {
                          handleChangePassword();
                        }}
                      >
                        {t("save_new_password")}
                      </Button>
                    </HStack>
                  </Box>
                </TabPanel>
                <TabPanel>
                  <DeleteAccount
                    user={user}
                    verifyPassword={handleVerifyPassword}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
          <Box
            shadow="lg"
            borderRadius="lg"
            bg={useColorModeValue("main.elementBgLight", "main.elementBgDark")}
            mt={5}
            maxW={"100%"}
          >
            <Heading ml={5} mb={4} mt={4}>
              {t("your_favs")}
            </Heading>
            <List m={2}>
              {favs.map((fav) => {
                const updatedFav = { ...fav, takable: true };
                return (
                  <SearchResultCard
                    key={fav.id}
                    course={updatedFav}
                    border={true}
                    remove_fav={remove_fav}
                  />
                );
              })}
            </List>
          </Box>
        </SimpleGrid>
      </Box>
      <Spacer />
      <BottomBar />
    </Flex>
  );
}

export default Profile;
