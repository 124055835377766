import { useState, useEffect, useContext } from "react";
import {
  Stack,
  Heading,
  Text,
  Box,
  Input,
  Button,
  FormControl,
  FormLabel,
  Flex,
  useColorModeValue,
  useMediaQuery
} from "@chakra-ui/react";
import { useToast } from '@chakra-ui/react'
import request from "../api";
import { useSearchParams } from "react-router-dom";
import AuthContext from '../AuthContext';
import { useTranslation } from "react-i18next";





export default function ForgotPasswordComponent() {
  const [email, setEmail] = useState("");
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");

  const [successMessage, setSuccessMessage] = useState("");
  const { isSignedIn, role } = useContext(AuthContext);
  const allowedRoles = ['ADMIN', "STUDENT", 'MODERATOR'];
  const { t } = useTranslation();


  const toast = useToast()

  async function handleForgotPassword() {


    try {

      await request(`${process.env.REACT_APP_BACKEND_URL}/api/v1/auth/forgot-password`, 'POST', {
        email: email.toLowerCase().trim(),
      });

      toast({
        title: 'Sended!',
        description: 'You will receive an email if you are registered!',
        status: 'success',
        duration: 4000,
        isClosable: true,
      })

    } catch (error) {
      toast({
        title: 'Damn, something went wrong...',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  }

  async function handleEmailChange(e) {
    setEmail(e.target.value);
    setSuccessMessage("");
  }

  return (

    <> {!isSignedIn && !allowedRoles.includes(role) ?

      <Flex justify="center"
        align="center"
        height={isLargerThan768 ? "100vh" : null}
        maxW={isLargerThan768 ? "40vw" : null}
      >

        <Stack
          bg={useColorModeValue("main.elementBgLight", "main.elementBgDark")}
          rounded="xl"
          p={8}
          spacing={6}
          direction="column"
          shadow="lg"


        >
          <Heading lineHeight={1.1} fontSize="4xl">
            {t("forgot_your_password")}
          </Heading>
          <Text fontSize="md" >
            {t("forgot_password_text")}
          </Text>

          <Box as="form">
            <Stack spacing={4}>
              <FormControl>
                <FormLabel htmlFor="email" >
                  {t("email_address")}
                </FormLabel>
                <Input
                  placeholder={t("your_email")}
                  value={email}
                  onChange={handleEmailChange}
                  borderColor="gray.800"
                  _placeholder={{ color: "gray.500" }}
                  id="email"

                />

                {successMessage && (
                  <Text color="green.500">{successMessage}</Text>
                )}
              </FormControl>

              <Button
                fontFamily="heading"
                onClick={handleForgotPassword}
                disabled={!email}
                bg="main.primary"
                _hover={{ bg: "main.secondary" }}
                color="white"
                mt={4}
              >
                {t("send_reset_link")}
              </Button>
            </Stack>
          </Box>
        </Stack>

      </Flex>
      : window.location.href = "/"}
    </>
  );
}
